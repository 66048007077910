@import "../../../_variables.scss";

$grid-header-color: darken($storychart-blue, 5%);

.table-component {
    &.MuiDataGrid-root {
        &.navigate-on-click {
            .MuiDataGrid-row {
                cursor: pointer;
            }
        }
    }

    &.compact {
        $compact-text-size: 10px;
        $compact-icon-size: 16px;

        .MuiDataGrid-footer, .MuiTablePagination-toolbar {
            min-height: 36px;

            * {
                font-size: $compact-text-size;

                .MuiSelect-icon {
                    top: calc(50% - #{ $compact-icon-size / 2 });
                }
            }

            .MuiSvgIcon-root {
                font-size: $compact-icon-size;
            }

            .MuiTablePagination-actions {
                margin-left: 10px;
            }

            .MuiTablePagination-selectRoot {
                margin-right: 10px;
            }

            .MuiToolbar-gutters {
                padding-left: 0;
            }

            .MuiDataGrid-selectedRowCount {
                flex-grow: 0;
                flex-shrink: 1;
            }
        }

        .MuiTablePagination-actions {
            > button {
                padding: 4px;
            }
        }
    }

    .MuiDataGrid-cell.is-dirty {
        border: 1px solid $red-1;
        background-color: rgba($red-1, 0.025);
    }

    .MuiDataGrid-cell {
        position: relative;
    }

    .cell-tooltip-source {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

.cell-tooltip-card {
    margin: 16px;
    padding: 16px;
}