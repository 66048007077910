﻿@import "../../_variables.scss";

.MuiTreeView-root {
    overflow-x: hidden;

    div.MuiTreeItem-label {
        font-size: 16px;
    }

    .MuiSvgIcon-root, .MuiIcon-root {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 1.375em !important; // Scale icon size with the font size for this level...
        margin-right: 12px;
    }

    .MuiTreeItem-label svg.icon {
        padding: 0;
        margin-top: auto;
        margin-bottom: auto;
        width: 1.375em; // Scale icon size with the font size for this level...
        min-width: 1.375em; // Scale icon size with the font size for this level...
        height: 1.375em; // Scale icon size with the font size for this level...
        min-height: 1.375em; // Scale icon size with the font size for this level...
        margin-right: 12px;
    }

    li.MuiTreeItem-root {
        ul {
            border-left: 1px solid $grey-6;
        }
    }

    div.MuiTreeItem-content {
        width: auto;
        padding-left: 4px;

        * {
            color: $grey-3;

            path {
                stroke: $grey-5;
                fill: $grey-5;
            }
        }
    }

    li.MuiTreeItem-root {
        ul {
            border-left: 1px solid $grey-6;
        }
    }

    .MuiTreeItem-iconContainer {
        width: unset;
        max-width: 16px;
        margin: 0;
    }

    .MuiTreeItem-label {
        padding: 0;
    }

    ul:not(:first-child) {
        margin-left: 12px;
        padding-left: 12px;
    }

    li.active > .MuiTreeItem-content {
        margin-left: -13px;
        padding-left: 15px;
        border-left: 2px solid $blue-1;

        * {
            color: $blue-1;

            path {
                fill: $blue-1;
            }
        }
    }

    .icon {
        padding: 0;
    }
}
