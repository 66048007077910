﻿@import "../../_variables.scss";

div.MuiDataGrid-root {
    border: none;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);

    .MuiDataGrid-columnHeaders {
        color: $primary-color;
        background-color: $grey-8;
        overflow: hidden;
        border-bottom: none;
    }

    .MuiDataGrid-columnHeadersInner, .MuiDataGrid-columnHeaderWrapper {
        align-self: stretch;
    }

    .MuiDataGrid-cell {
        border-bottom: 1px solid $grey-6;
        font-size: 12px;
        color: $grey-4;
        align-items: center;
    }

    .MuiDataGrid-cell--editing input {
        font-size: 12px;
    }

    .MuiDataGrid-columnHeader, .MuiDataGrid-cell {
        border-right: 1px solid $grey-6;
    }

    .MuiDataGrid-columnSeparator {
        opacity: 0 !important;
    }

    .MuiDataGrid-columnHeader {
        line-height: 1.125;
        height: 100%;
        display: flex;

        .MuiDataGrid-columnHeaderTitleContainer {
            padding: 0;
            overflow: visible;

            .MuiDataGrid-columnHeaderTitle {
                white-space: normal;
            }
        }

        > div > span {
            display: flex;
            height: 24px;
        }
    }

    .MuiDataGrid-footerContainer {
        box-shadow: black 0px 2px 4px;

        #export-to-excel {
            margin: 0 16px;
        }
    }
}