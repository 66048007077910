.kpi-indicator {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr minmax(0, max-content);
    grid-template-rows: minmax(0, max-content) 1fr minmax(0, max-content);
    color: #2a3f5f;
    text-align: center;

    .main-block {
        grid-column: 1;
        grid-row: 2;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .value {
            font-size: 3.2vw;
        }

        .label {
            font-size: 1vw;
            line-height: 1.5;
        }
    }

    .main-delta-label {
        grid-column: 1;
        grid-row: 3;
        padding: 8px;
        font-size: 0.875vw;
    }

    .main-delta {
        grid-column: 1;
        grid-row: 3;
    }

    .deltas {
        grid-column: 2;
        grid-row: 1 / 4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-left: 1px dotted black;

        > *:not(:first-child) {
            border-top: 1px dotted black;
        }
    }

    .delta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 8px;

        &.positive {
            .value {
                color: #8dc63f;
            }
        }

        &.negative {
            .value {
                color: #ed1c24;
            }
        }

        .value {
            font-size: 1.6vw;
        }

        .label {
            font-size: 0.875vw;
        }
    }
}
