@import "../../../_variables.scss";

.timeline-container {
    display: flex;
    flex-direction: row;
    height: 100%;

    .timeline {
        flex: 1 1 auto;
        height: 100%;

        > * {
            height: 100%;
        }
    }

    .legend {
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        margin: 16px;

        .activity {
            display: flex;
            flex-grow: 1;
            align-items: center;
            margin-bottom: 2px;

            .color-block {
                height: 100%;
                width: 50px;
            }

            .title {
                margin: 0 8px;
            }
        }
    }

    .vis-timeline {
        border-width: 0 1px 0 0;
        visibility: inherit !important;
    }

    .vis-item {
        border-width: 0;
        border-radius: 0;
        top: 0 !important;
        height: 100% !important;

        &.vis-selected {
            border: yellow 4px solid;
        }
    }

    .vis-content {
        height: 100%;

        .vis-labelset {
            height: 100%;

            .vis-label .vis-inner {
                padding: 12px;
            }

            > * {
                @for $i from 1 through 20 {
                    &:first-child:nth-last-child(#{$i}),
                    &:first-child:nth-last-child(#{$i}) ~ * {
                        height: 100% / $i !important;
                    }
                }

                display: flex;
                align-items: center;
            }
        }

        .vis-itemset {
            height: 100% !important;

            .vis-foreground, vis-background {
                > .vis-group {
                    @for $i from 1 through 20 {
                        &:first-child:nth-last-child(#{$i}),
                        &:first-child:nth-last-child(#{$i}) ~ * {
                            height: 100% / $i !important;
                        }
                    }
                }
            }
        }
    }
}

.timeline-tooltip {
    display: flex;
    flex-direction: column;

    .title {
        color: $storychart-blue;
        font-weight: bold;
    }

    .dates {
        margin-top: 8px;

        .date {
            font-size: 0.75rem;
            display: flex;
            flex-direction: row;
            width: 100%;

            .title {
                flex-grow: 1;
                margin-right: 16px;
            }
        }
    }

    .message {
        font-size: 0.75rem;
        margin-top: 8px;
    }
}

// Timeline is offset by 50% in the tooltip due to the centering logic.  Move it back to where it should be...
.minimap-hover-tooltip .vis-center {
    transform: translateX(-50%);
    overflow: visible;
}