@import "../../../../../_variables.scss";

.poi-info {
    min-width: 600px;
    padding: 1rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(4, minmax(24px, max-content));
    align-items: center;
    grid-gap: 1.5rem;

    .label {
        font-size: 1.125rem;
        font-weight: bold;
        color: $storychart-blue-darker;
        padding-right: 2rem;
    }

    .MuiFormControl-fullWidth {
        padding-bottom: 0;
    }
}