@import "../../_variables.scss";

.progressBarContainer {
    background-color: $grey-7 !important;
    border-radius: 9px;
    overflow: hidden;
}

.progressBarLabel {
    font-size: 12px;
    white-space: nowrap;
    padding: 0 8px;
    min-width: 0;

    &.inside {
        color: white;
    }

    &.outside {
        color: $grey-4;
    }
}

.warning {
    .progressBarContainer > div {
        background-color: $storychart-dark-orange !important;
    }
}

.deemphasised, .deemphasized {
    .progressBarContainer > div {
        background-color: $grey-5 !important;
    }
}

.neutral {
    .progressBarContainer > div {
        background-color: $blue-2 !important;
    }
}

.positive {
    .progressBarContainer > div {
        background-color: $green-2 !important;
    }
}

.negative {
    .progressBarContainer > div {
        background-color: $red-2 !important;
    }
}