.tabs-container {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
}

.tabpanel {
    grid-area: 2/1/2/1;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
}

.tabpanel-hidden {
    z-index: -1;
}