﻿@import "../../_variables.scss";

.date-picker-container {
    .MuiIconButton-root {
        svg.icon {
            width: 17px;
            height: 17px;
            fill: $grey-4;
        }
    }
}