@import "../../_variables.scss";

.treeview-component-container {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: auto 1fr;
    grid-template-areas: "search-row" "tree";

    .search-row {
        grid-area: search-row;
        margin-bottom: 8px;
    }

    .MuiTreeView-root {
        grid-area: tree;
    }

    mark {
        background-color: rgba($storychart-yellow, 0.4);
    }

    .no-items {
        padding: 8px;
        color: $grey-1;
    }
}
