﻿@import "../../_variables.scss";

div.MuiAvatar-root {
    font-size: 1rem;

/*    > svg.icon {
        display: flex;
        align-content: center;
        width: 36px;
        height: 36px;

        > g {
            margin-left: -2px;
        }
    }*/

    &[color=red] {
        background-color: $red-1;
    }

    &[color=orange] {
        background-color: $orange-1;
    }

    &[color=yellow] {
        background-color: $yellow-1;
    }

    &[color=green] {
        background-color: $green-1;
    }

    &[color=blue] {
        background-color: $blue-1;
    }

    &[color=darkblue] {
        background-color: $dark-blue-1;
    }
}