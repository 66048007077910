@import "../../_variables.scss";

.metadata-editor {
    .no-metadata {
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        padding: 18.5px 14px;
        text-align: center;
        font-size: 1rem;
    }

    .table {
        width: 100%;

        td:not(:last-child) {
            padding-top: 1px !important;
            padding-bottom: 1px !important;
            padding-left: 5px !important;
            padding-right: 5px !important;

            > .MuiFormControl-root {
                padding: 0;
            }
        }
    }

    .footer {
        display: flex;
        margin-top: 6px;
        justify-content: center;
    }
}
