.map-controls {
    position: absolute;
    right: 2px;
    top: 2px;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 2px;
}

#map-style-menu {
    .map-style-option {
        .selected {
            font-weight: bold;
        }
    }
}