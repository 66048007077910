.snackbar-root {
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 40px);

    .MuiCard-root {
        display: block;

        .MuiCollapse-container {
            max-height: calc(100vh - 100px);

            .MuiCollapse-wrapper {
                overflow-y: auto;
                max-height: inherit;

                .MuiCollapse-wrapperInner {
                    width: unset;
                    min-width: 100%;
                }

                .MuiPaper-root {
                    overflow: auto;
                }
            }
        }
    }
}

.detailed-error-text {
    max-width: calc(100vw - 40px);
    margin-top: -2px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);

    .MuiCollapse-wrapperInner {
        background-color: white;
        overflow: auto;
    }

    pre {
        padding: 8px 16px;
    }
}