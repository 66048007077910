﻿@import "../../_variables.scss";

span.MuiLinearProgress-root {
    background-color: $grey-7;
    height: 8px;
    border-radius: 1rem;

    .MuiLinearProgress-bar {
        border-radius: 1rem;
    }
}

.linear-progress {
    .linear-progress-label {
        font-size: 18px;
        font-weight: 700;
        margin-left: 8px;
        min-width: 50px;
    }
}