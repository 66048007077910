@import "../../../_variables.scss";

$predefined-classes: ( "positive": $chart-green-3, "negative": $chart-red-3, "neutral": $chart-darkblue-3 );

.kpi-delta {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr auto auto;
    grid-template-areas: "title value delta" "progressbar progressbar progressbar";
    grid-column-gap: 8px;
    grid-row-gap: 4px;
    align-items: center;
    font-size: 0.75rem;

    .title {
        grid-area: title;
        font-weight: bold;
        color: $grey-2;
    }

    .value {
        grid-area: value;
    }

    .delta {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            width: 16px;
            height: 16px;
            padding: 0;
        }
    }

    .progressbar {
        grid-area: progressbar;
    }

    @each $class in map-keys($predefined-classes) {
        &.#{$class} {
            .secondary .progressBarContainer div {
                background-color: rgba(map-get($predefined-classes, $class), 0.25) !important;
            }

            .primary .progressBarContainer {
                background-color: transparent !important;
            } 
            
            .primary .progressBarContainer div {
                background-color: map-get($predefined-classes, $class);
            }

            .delta {
                color: map-get($predefined-classes, $class);

                svg path {
                    fill: map-get($predefined-classes, $class);
                }
            }
        }
    }
}
