﻿@import "./_colors.scss";

$primary-color: $blue-1;
$secondary-color: $blue-3;

$background-color: white;

$vdt-highlight-stroke: #430071;
$vdt-highlight-fill: rgba(67, 0, 113, 0.05);

$storychart-blue: $blue-1;
$storychart-blue-darker: $dark-blue-1;
$storychart-red: $red-1;

$storychart-color-1: $red-2;
$storychart-dark-red: $storychart-color-1;

$storychart-color-2: $red-1;
$storychart-red: $storychart-color-2;

$storychart-color-3: $orange-2;
$storychart-dark-orange: $storychart-color-3;

$storychart-color-4: $orange-1;
$storychart-orange: $storychart-color-4;

$storychart-color-5: $orange-3;
$storychart-light-orange: $storychart-color-5;

$storychart-color-6: $yellow-1;
$storychart-yellow: $storychart-color-6;

$storychart-color-7: $green-1;
$storychart-green: $storychart-color-7;

$storychart-color-8: $green-2;
$storychart-dark-green: $storychart-color-8;

$storychart-color-9: $blue-1;
$storychart-light-blue: $storychart-color-9;

$storychart-colors: 
    $storychart-color-1, 
    $storychart-color-2, 
    $storychart-color-3, 
    $storychart-color-4, 
    $storychart-color-5, 
    $storychart-color-6, 
    $storychart-color-7, 
    $storychart-color-8, 
    $storychart-color-9;

$primary-font: "Helvetica Neue", FontAwesome, Arial;

// Layout...
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;

$sides: left, top, right, bottom;

$red: $storychart-red;
$green: $storychart-green;

$border-color: rgba(0, 0, 0, 0.23);

$button-outline-background: #FFFFFF;

$drop-shadow-light: 4px 4px 20px rgba(0, 0, 0, 0.05);