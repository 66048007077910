@import "../../_variables.scss";

.sidenav {
    width: 400px;
    background-color: $grey-8;
    color: $grey-3;
    border-right: 1px solid $grey-6;
    grid-area: sidenav;
    position: relative;

    &.open {
    }

    &.closed {
        width: 0;
    }

    .collapse-expand-button {
        position: absolute;
        overflow: visible;
        right: 12px;
        top: 20px;
        z-index: 1200;

        button {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0;
            width: 24px;
            height: 24px;
            border: 0.374911px solid $grey-6;
            box-shadow: 2.99929px 2.99929px 14.9964px rgba(0, 0, 0, 0.05);
            background-color: white;

            &:hover {
                background-color: lighten($primary-color, 45);
            }

            .icon {
                margin-left: -3px;
            }
        }
    }

    .drawer {
        display: grid;
        grid-template-rows: auto 1fr auto;
        height: 100vh;
        overflow: hidden;

        @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */
            height: -webkit-fill-available;
        }

        .drawer-header {
            display: flex;
            flex-direction: column;
            flex-shrink: 1;
            align-items: stretch;

            .top-row {
                display: grid;
                grid-template-columns: auto 1fr 12px;
                max-width: 100%;
                height: 64px;
                box-sizing: border-box;
                gap: 16px;
                align-items: center;

                .logo-container {
                    justify-content: center;
                    margin-left: 24px;

                    .logo {
                        img {
                            height: 36px;
                        }
                    }
                }

                .tenant-name {
                    font-size: 14px;
                }
            }

            hr {
                margin: 0 16px 2px 16px;
                box-sizing: border-box;
            }

            .search-row {
                display: flex;
                padding: 0 8px;

                div.MuiInputBase-root, .MuiInputBase-input, .MuiPaper-root {
                    color: $grey-3;
                    font-size: 12px;
                    padding: 2px 0;

                    fieldset {
                        border-width: 0;
                    }

                    .MuiInputAdornment-positionStart {
                        margin-left: 0;
                    }
                }
            }

            .clear-search {
                margin: 0;
                padding: 6px;
                color: white;
            }
        }

        .no-items {
            padding: 16px;
            color: $grey-1;
        }


        .menu-items {
            flex-grow: 1;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .drawer-footer {
            display: flex;
            justify-content: center;
            flex-grow: 0;
            flex-shrink: 0;

            .user-profile {
                flex: 1 1 auto;
                flex-direction: column;
                padding: 8px;
                align-self: center;

                div {
                    font-size: 13px;

                    &:first-child {
                        color: $grey-5;
                        font-size: 12px;
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .loading-spinner-inline {
        margin-top: 20px;
    }

    * {
        scrollbar-width: thin;
        scrollbar-color: $grey-4 $grey-6; /* scroll thumb & track */
        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: $grey-6;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $grey-4;
            border: 1px solid $grey-6;
        }
    }

    &.hide-minimap {
        display: none;
    }
}


.nav-tree {
    .MuiSvgIcon-root, .MuiIcon-root {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 1.375em !important; // Scale icon size with the font size for this level...
        margin-right: 12px;
    }

    .MuiTreeItem-label svg.icon {
        padding: 0;
        margin-top: auto;
        margin-bottom: auto;
        width: 1.375em; // Scale icon size with the font size for this level...
        min-width: 1.375em; // Scale icon size with the font size for this level...
        height: 1.375em; // Scale icon size with the font size for this level...
        min-height: 1.375em; // Scale icon size with the font size for this level...
        margin-right: 12px;
    }

    div.MuiTreeItem-content {
        width: auto;
        padding-left: 4px;
    }

    li.MuiTreeItem-root {
        ul {
            border-left: 1px solid $grey-6;
        }
    }

    .MuiTreeItem-iconContainer {
        width: unset;
        max-width: 16px;
        margin: 0;
    }

    .MuiTreeItem-label {
        padding: 0;
    }

    ul:not(:first-child) {
        margin-left: 12px;
        padding-left: 12px;
    }

    .menu-item-label {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        font-size: 12px;
        line-height: 1.125;
    }

    .parent {
        > .MuiTreeItem-content > .MuiTreeItem-label > .menu-item-label {
            font-weight: bold;
        }
    }

    mark {
        background-color: rgba($secondary-color, 0.4);
    }

    li.active > .MuiTreeItem-content {
        margin-left: -13px;
        padding-left: 15px;
        border-left: 2px solid $blue-1;

        * {
            color: $grey-3;

            path {
                stroke: $grey-5;
                fill: $grey-5;
            }
        }

        .menu-item-label .material-icons, .menu-item-label .icon {
            color: $blue-1;

            path {
                stroke: $blue-1 !important;
            }
        }
    }

    li.active.tooltip-target > .MuiTreeItem-content {
        .menu-item-label * {
            color: $blue-1;

            path {
                stroke: $blue-1 !important;
            }
        }
    }
    // Remove the extra nesting level introduced by the TreeView for the Minimap...
    li.leaf {
        > ul {
            margin-left: -16px;
            border-left: none;
        }
    }
}
