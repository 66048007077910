﻿@import "../../_variables.scss";

header.MuiAppBar-root {
    &.MuiAppBar-colorPrimary {
        background-color: white;
        color: $grey-3;
    }

    box-shadow: $drop-shadow-light;
}
