@import "../../../_variables.scss";

.markdown {
    font-size: 0.875rem;

    p {
        font-size: 1em;
    }

    h1, h2, h3, h4, h5, h6 {
        text-align: left;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    h1 {
        color: $red-2;
    }

    h2 {
        color: $blue-2;
    }

    h3 {
        color: $green-2;
    }

    h4 {
        color: $orange-2;
    }

    h5 {
        color: $yellow-2;
    }

    h6 {
        color: $grey-4;
    }

    table {
        width: 100%;
        border-spacing: 0;
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.03);

        td, th {
            padding: 8px;
        }

        td {
            color: $grey-4;
            border-right: 1px solid $grey-7;
            border-bottom: 1px solid $grey-7;
        }

        td:last-child {
            border-right: none;
        }

        tr:last-child {
            td {
                border-bottom: none;
            }
        }

        th {
            background-color: $grey-7;
            color: $blue-1;
            border-right: 1px solid $grey-7;
            margin: -1px;
            text-align: left;

            &:last-child {
                border-right: none;
            }
        }
    }

    code {
        color: $storychart-orange;
        font-size: 1.125em;
    }
}