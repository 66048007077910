﻿@import "../../_variables.scss";

// Edit Dialog...
div.gantt_cal_light {
    box-sizing: content-box !important;
}

div.gantt_drag_marker {
    * {
        font-family: inherit !important;
    }
}

div.dhtmlx-gantt-container {
    border-radius: 0;

    .gantt_container {
        font-family: inherit;
        font-size: inherit;
    }

    > .gantt_layout_root {
        border: none;
    }

    .gantt_scale_line {
        border-top: none !important;

        &:not(:last-child) {
            .gantt_scale_cell {
                border-bottom: 0.5px solid $grey-6 !important;
            }
        }
    }

    .gantt_scale_cell, .gantt_task_cell, .gantt_grid_head_cell, .gantt_cell:not(.gantt_last_cell) {
        border-right: 0.5px solid $grey-6 !important;
    }

    .gantt_grid_head_cell.gantt_last_cell {
        left: 1px; // The last header cell is always 1px smaller reasons only the library developers will comprehend, so we have to shift the last cell slightly to the right in order to align the border...
    }

    .gantt_grid .gantt_grid_scale, .gantt_grid .gantt_row {
        border-right: 0.5px solid $grey-6;
    }

    .gantt_grid_head_cell, .gantt_scale_cell {
        text-transform: none !important;
        color: #3777BB !important;
        background-color: $grey-8;
        font-weight: 500 !important;
    }
    // Remove box-shadow from header...
    div .gantt_grid_scale:after, div.gantt_scale_line:last-child:after {
        display: none;
    }

    .gantt_data_area .gantt_task_row:last-child, .gantt_grid_data .gantt_row:last-child {
        border-bottom: none;
    }

    .gantt_grid {
        .gantt_grid_data {
            left: 0 !important;

            .gantt_cell {
                color: $grey-5;
            }
        }

        .gantt_grid_head_cell {
            padding: 0 6px;
            text-align: left;
        }
    }

    .gantt_grid_data .gantt_row.gantt_selected, .gantt_grid_data .gantt_row.odd.gantt_selected, .gantt_task_row.gantt_selected {
        background-color: rgba($grey-5, 0.2);

        .gantt_cell {
            color: $grey-2;
        }
    }

    .gantt_grid_data .gantt_row.odd:hover, .gantt_grid_data .gantt_row:hover {
        background-color: rgba($grey-5, 0.3);
    }
    // Hide the always-visible link targets when the row is selected...
    .gantt_task_line.gantt_selected:not(:hover) .gantt_link_control div {
        display: none;
    }

    .gantt_task_line {
        border-radius: 4px;
        font-weight: normal;
        background-color: $blue-1;
        border: none;
    }
}
