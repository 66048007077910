.loading-screen {
    width: 512px;
    margin: auto;
    display: block;
    margin: 0;
    position: absolute;
    top: calc(50% - 130px);
    left: calc(50% - 256px);

    .logo {
        width: 100%;
    }

    .loading {
        margin-left: 192px;
    }
}
