div.users-list {
    display: flex;
    flex-direction: column;
    height: 800px;

    .MuiPaper-root {
        height: 100%;
    }

    .MuiButton-root {
        margin-top: 1em;
        height: 32px;
        align-self: center;
    }
}