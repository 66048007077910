@import "../../../_variables.scss";

table.heatmap-table {
    table-layout: fixed;

    th {
        color: $grey-3;
    }

    td {
        text-align: center;
    }
}