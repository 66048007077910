﻿@import "../../_variables.scss";

div.MuiAutocomplete-root {
    border-color: $grey-5;

    .MuiChip-root {
        background-color: $grey-6;
        border-radius: 4px;

        .MuiChip-label {
            color: $grey-3;
            font-size: 11px;
        }

        .MuiIconButton-root {
            padding: 4px;

            svg > path {
                fill: $grey-3;
            }
        }
    }

    .MuiInputBase-sizeSmall .MuiAutocomplete-endAdornment {
        top: calc(50% - 12px);
    }

    &.MuiAutocomplete-hasPopupIcon .MuiInputBase-root {
        padding-right: 18px;
    }

    &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiInputBase-root {
        padding-right: 36px;
    }
}

.MuiAutocomplete-popper {
    .highlight-text {
        font-weight: 700;
        color: $storychart-blue;
    }
}

.MuiAutocomplete-listbox {
    list-style: none;
    margin: 0;
    padding: 8px 0;
    max-height: 40vh;
    overflow: auto;
}