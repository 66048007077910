.crud-panel {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
    box-sizing: content-box;

    .crud-buttons {
        grid-area: 2 / 1 / 3 / 3;
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: 8px;
    }
}
