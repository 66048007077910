﻿@import "../../_variables.scss";

.MuiCheckbox-root {
    .check-container {
        display: flex;
        align-items: center;
        justify-content: center;

        &[font-size=large] {
            height: 24px;
            width: 24px;
        }

        &[font-size=medium] {
            height: 20px;
            width: 20px;
        }

        &[font-size=small] {
            height: 12px;
            width: 12px;
        }
    }

    &.MuiCheckbox-colorPrimary {
        .check-container {
            border-color: $primary-color;

            svg * {
                fill: $primary-color;
            }
        }
    }

    &.MuiCheckbox-colorSecondary {
        .check-container {
            border-color: $secondary-color;

            svg * {
                fill: $secondary-color;
            }
        }
    }

    &.MuiCheckbox-colorDefault {
        .check-container {
            border-color: $grey-3;

            svg * {
                fill: $grey-3;
            }
        }
    }

    &.Mui-disabled {
        .check-container {
            border-color: $grey-5;

            svg * {
                fill: $grey-5;
            }
        }
    }
}
