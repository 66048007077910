﻿@import "../../_variables.scss";

div.MuiPaper-root.MuiAlert-root {
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
    color: $grey-2;
    display: flex;
    align-items: center;
    padding: 6px 16px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;

    .MuiAlert-action {
        align-items: flex-start;
        padding: 0 0 0 16px;
        margin-left: auto;
        margin-right: -8px;
    }

    .MuiAlert-action .MuiSvgIcon-root {
        fill: $grey-4;
        width: 14px;
        height: 14px;
    }

    .MuiAlert-icon {
        padding: 0;
        margin-right: 12px;
        display: flex;
    }

    .MuiAlert-message {
        padding: 8px 0;
    }

    &.MuiAlert-standardError {
        background: $red-4;

        .MuiAlert-icon > svg, .MuiAlert-icon > svg > * {
            fill: $red-1;
        }
    }

    &.MuiAlert-standardWarning {
        background: $yellow-4;

        .MuiAlert-icon > svg, .MuiAlert-icon > svg > * {
            fill: $yellow-2;
        }
    }

    &.MuiAlert-standardInfo {
        background: $dark-blue-4;

        .MuiAlert-icon > svg, .MuiAlert-icon > svg > * {
            fill: $dark-blue-2;
        }
    }

    &.MuiAlert-standardSuccess {
        background: $green-4;

        .MuiAlert-icon > svg, .MuiAlert-icon > svg > * {
            fill: $green-2;
        }
    }
}