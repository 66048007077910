﻿// Primary color pallette...
$red-1: #D9032B;
$red-2: #C32233;
$red-3: #DF4253;
$red-4: #FFE2E0;

$orange-1: #F08B39;
$orange-2: #EA832F;
$orange-3: #FF9F51;
$orange-4: #FFE3CD;

$yellow-1: #FFD400;
$yellow-2: #EEC601;
$yellow-3: #FFEA81;
$yellow-4: #FFF7CF;

$green-1: #8BC53F;
$green-2: #77AD2F;
$green-3: #A6DC5E;
$green-4: #E9F3DC;

$blue-1: #3777BB;
$blue-2: #2B6AAD;
$blue-3: #89BAED;
$blue-4: #D3E7FC;

$dark-blue-1: #132A58;
$dark-blue-2: #0E2044;
$dark-blue-3: #32508C;
$dark-blue-4: #DFE6F4;

// Utility colors...
$error-1: #D9032B;
$error-2: #C32233;
$error-3: #DF4253;
$error-4: #FFE2E0;

$warning-1: #F08B39;
$warning-2: #EA832F;
$warning-3: #FF9F51;
$warning-4: #FFE3CD;

$highlight-1: #FFD400;
$highlight-2: #EEC601;
$highlight-3: #FFEA81;
$highlight-4: #FFF7CF;

$success-1: #8BC53F;
$success-2: #77AD2F;
$success-3: #A6DC5E;
$success-4: #E9F3DC;

$grey-1: #111111;
$grey-2: #222222;
$grey-3: #565656;
$grey-4: #7D7D7D;
$grey-5: #AFAFAF;
$grey-6: #E5E5E5;
$grey-7: #F3F3F3;
$grey-8: #FCFCFC;

// Chart colors...
$chart-red-1: #FFC3CF;
$chart-red-2: #E95D77;
$chart-red-3: #E12649;
$chart-red-4: #D9032B;
$chart-red-5: #C20428;
$chart-red-6: #AB0625;
$chart-red-7: #8D041E;
$chart-red-8: #670417;

$chart-orange-1: #FFDFC6;
$chart-orange-2: #FFC494;
$chart-orange-3: #FC9F53;
$chart-orange-4: #F08B39;
$chart-orange-5: #DB7A2C;
$chart-orange-6: #C36920;
$chart-orange-7: #A65615;
$chart-orange-8: #80410E;

$chart-yellow-1: #FFF7D1;
$chart-yellow-2: #FFEB88;
$chart-yellow-3: #FFDE3E;
$chart-yellow-4: #FFD400;
$chart-yellow-5: #F3CA01;
$chart-yellow-6: #DDBB14;
$chart-yellow-7: #C1A411;
$chart-yellow-8: #A1890F;

$chart-green-1: #D0EBAC;
$chart-green-2: #B8E27F;
$chart-green-3: #A0D35C;
$chart-green-4: #8BC53F;
$chart-green-5: #7FB539;
$chart-green-6: #70A032;
$chart-green-7: #5E8728;
$chart-green-8: #507125;

$chart-lightblue-1: #9FCEFF;
$chart-lightblue-2: #69A6E5;
$chart-lightblue-3: #4E90D6;
$chart-lightblue-4: #3777BB;
$chart-lightblue-5: #30669F;
$chart-lightblue-6: #2C6096;
$chart-lightblue-7: #26517D;
$chart-lightblue-8: #21476F;

$chart-darkblue-1: #9BBBFC;
$chart-darkblue-2: #6A91DF;
$chart-darkblue-3: #30559F;
$chart-darkblue-4: #1D3970;
$chart-darkblue-5: #132A58;
$chart-darkblue-6: #0D1E41;
$chart-darkblue-7: #09162F;
$chart-darkblue-8: #050C1B;

$colors: (
    "red-1": $red-1, 
    "red-2": $red-2, 
    "red-3": $red-3, 
    "red-4": $red-4, 
    "orange-1": $orange-1, 
    "orange-2": $orange-2, 
    "orange-3": $orange-3, 
    "orange-4": $orange-4, 
    "yellow-1": $yellow-1, 
    "yellow-2": $yellow-2, 
    "yellow-3": $yellow-3, 
    "yellow-4": $yellow-4, 
    "green-1": $green-1, 
    "green-2": $green-2,
    "green-3": $green-3,
    "green-4": $green-4,
    "blue-1": $blue-1,
    "blue-2": $blue-2,
    "blue-3": $blue-3,
    "blue-4": $blue-4,
    "dark-blue-1": $dark-blue-1,
    "dark-blue-2": $dark-blue-2,
    "dark-blue-3": $dark-blue-3,
    "dark-blue-4": $dark-blue-4,
    "grey-1": $grey-1,
    "grey-2": $grey-2,
    "grey-3": $grey-3,
    "grey-4": $grey-4,
    "grey-5": $grey-5,
    "grey-6": $grey-6,
    "grey-7": $grey-7,
    "grey-8": $grey-8,
    "chart-red-1": $chart-red-1,
    "chart-red-2": $chart-red-2,
    "chart-red-3": $chart-red-3,
    "chart-red-4": $chart-red-4,
    "chart-red-5": $chart-red-5,
    "chart-red-6": $chart-red-6,
    "chart-red-7": $chart-red-7,
    "chart-red-8": $chart-red-8,
    "chart-orange-1": $chart-orange-1,
    "chart-orange-2": $chart-orange-2,
    "chart-orange-3": $chart-orange-3,
    "chart-orange-4": $chart-orange-4,
    "chart-orange-5": $chart-orange-5,
    "chart-orange-6": $chart-orange-6,
    "chart-orange-7": $chart-orange-7,
    "chart-orange-8": $chart-orange-8,
    "chart-yellow-1": $chart-yellow-1,
    "chart-yellow-2": $chart-yellow-2,
    "chart-yellow-3": $chart-yellow-3,
    "chart-yellow-4": $chart-yellow-4,
    "chart-yellow-5": $chart-yellow-5,
    "chart-yellow-6": $chart-yellow-6,
    "chart-yellow-7": $chart-yellow-7,
    "chart-yellow-8": $chart-yellow-8,
    "chart-green-1": $chart-green-1,
    "chart-green-2": $chart-green-2,
    "chart-green-3": $chart-green-3,
    "chart-green-4": $chart-green-4,
    "chart-green-5": $chart-green-5,
    "chart-green-6": $chart-green-6,
    "chart-green-7": $chart-green-7,
    "chart-green-8": $chart-green-8,
    "chart-lightblue-1": $chart-lightblue-1,
    "chart-lightblue-2": $chart-lightblue-2,
    "chart-lightblue-3": $chart-lightblue-3,
    "chart-lightblue-4": $chart-lightblue-4,
    "chart-lightblue-5": $chart-lightblue-5,
    "chart-lightblue-6": $chart-lightblue-6,
    "chart-lightblue-7": $chart-lightblue-7,
    "chart-lightblue-8": $chart-lightblue-8,
    "chart-darkblue-1": $chart-darkblue-1,
    "chart-darkblue-2": $chart-darkblue-2,
    "chart-darkblue-3": $chart-darkblue-3,
    "chart-darkblue-4": $chart-darkblue-4,
    "chart-darkblue-5": $chart-darkblue-5,
    "chart-darkblue-6": $chart-darkblue-6,
    "chart-darkblue-7": $chart-darkblue-7,
    "chart-darkblue-8": $chart-darkblue-8
);
