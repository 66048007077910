@import "../../_variables.scss";

.theme-showcase {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    padding-right: 0;

    h1 {
        width: 100%;
    }

    > * {
        margin-right: 1em;
        margin-bottom: 1em;
    }

    .section {
        align-self: stretch;
        display: flex;
        flex-direction: column;

        > *:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    .badge-grid {
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 50px;
            min-height: 24px;
        }
    }

    ul.icons {
        width: 100%;
        list-style: none;
        margin: 0;
        padding-inline-start: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, 128px);
        gap: 8px;
        justify-content: center;

        li {
            display: grid;
            grid-template-rows: auto 1fr;
            align-items: center;
            border: 1px solid #AFAFAF;
            border-radius: 4px;

            .icon {
                margin: 8px;
                justify-self: center;
            }

            h6 {
                background-color: $grey-7;
                align-self: stretch;
                text-align: center;
                padding: 8px;
                margin: 0;
                color: $blue-2;
                word-wrap: break-word;
                max-width: 100%;
                overflow: hidden;
            }
        }
    }

    .tabpanel {
        padding: 1rem;
    }
}