﻿@import "../../_variables.scss";

.MuiPaper-root.MuiDialog-paper {
    min-width: 400px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);

    .MuiDialogActions-root {
        padding: 16px 24px;
    }

    .MuiDialogContent-root {
        overflow-y: hidden;
        font-size: 14px;
        padding: 16px 24px;
    }

    .MuiDialogTitle-root {
        color: $grey-1;
        font-size: 1rem;
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .MuiDialogContentText-root {
        background-color: $blue-4;
        border-radius: 8px;
        padding: 16px;
        margin-top: -16px;
        margin-bottom: 16px;
        font-size: 0.875rem;
    }
}

.MuiPaper-root.MuiPopover-paper {
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
}

