@import "../../../_variables.scss";

.kpi-profile {
    display: grid;
    width: 100%;
    height: 100%;
    padding: 16px;
    align-items: center;

    &.full {
        grid-gap: 4px;
        grid-template-rows: auto auto 1fr;
        grid-template-columns: auto 1fr;
        grid-template-areas:
            "title delta" "value value" "chart chart";
    }

    &.compact { 
        grid-gap: 4px;
        grid-template-rows: auto 1fr;
        grid-template-columns: auto 1fr auto;
        grid-template-areas:
            "title title delta" "value chart chart";
    }

    .title {
        grid-area: title;
        font-size: 1.25em;
        color: $grey-5;
    }

    .delta {
        background-color: $grey-7;
        border-radius: 8px;
        padding: 2px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75rem;
        width: auto;
        justify-self: flex-end;

        .icon {
            padding: 0;
        }

        &.positive {
            color: $green-1;
        }

        &.negative {
            color: $red-1;
        }
    }

    .value {
        grid-area: value;
        font-size: 2em;
        font-weight: bold;
        align-self: flex-start;
    }

    .chart {
        grid-area: chart;
        position: relative;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        overflow: hidden;
    }
}
