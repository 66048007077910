@import "../../../_variables.scss";

.vdt-and-controls-container {
    display: grid;
    grid-template-areas: "overlay";
    width: 100%;
    height: 100%;
}

.vdt-container {
    grid-area: overlay;
    width: 100%;
    height: 100%;
    position: relative;

    .icon {
        box-sizing: border-box;
    }
}

// Rendering an SVG inside a relatively positioned div causes massive slowdowns in Chrome,
// so we have to nest the actual VDT inside an absolutely-positioned div to remedy this...
.vdt-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
}

.controls-container {
    grid-area: overlay;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    pointer-events: none;

    > * {
        pointer-events: auto;
    }
}

.vdt-controls {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2px;
}

.zoom-controls {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    margin: 2px;

    .MuiIconButton-root {
        padding: 6px;
    }
}

.vdt-and-controls-container:hover {
    .vdt-controls {
        visibility: visible;
    }
}

.vdtLink {
    fill: none;
    stroke: $grey-5;
    stroke-width: 4px;
    stroke-linejoin: round;
}

.vdtNode {
    font: 10px $primary-font;
    color: $grey-2;
    height: 100%;

    .vdtNodeContainer {
        height: 100%;
        box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
        border-radius: 6px;

        .vdtNodeBackground {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            overflow: hidden;
            border-radius: 6px;
            z-index: -1;
        }

        .vdtNodeBody {
            box-sizing: border-box;
            padding: 8px 8px 16px 8px;
            height: 100%;
            background: transparent;
            opacity: 1;

            .vdtFieldsContainer {
                display: grid;
                grid-template-rows: auto auto 1fr auto;
                grid-template-columns: 1fr;
                align-items: center;
                width: 100%;
                height: 100%;
                grid-template-areas:
                    "header" "header-separator" "values" "progressbar";
            }

            .vdtChartContainer {
                display: grid;
                grid-template-rows: auto 1fr;
                grid-template-columns: 1fr;
                grid-row-gap: 4px;
                width: 100%;
                height: 100%;
                grid-template-areas:
                    "header" "chart";

                .vdtChart {
                    grid-area: chart;
                }
            }

            .vdtCustomContainer {
                display: grid;
                grid-template-rows: auto auto 1fr auto;
                grid-template-columns: 1fr;
                align-items: center;
                width: 100%;
                height: 100%;
                grid-template-areas:
                    "header" "header-separator" "content" "progressbar";

                .vdtNodeContents {
                    grid-area: content;
                    align-self: center;
                    justify-self: center;
                }
            }

            .vdtNodeHeader {
                grid-area: header;
                font-size: 1.25em;
                line-height: 1.25;
                font-weight: bold;
            }

            .vdtNodeHeaderSeparator {
                grid-area: header-separator;
                width: 100%;
            }

            .vdtNodeExpandCollapseButton {
                position: absolute;
                bottom: -12px;
                left: calc(50% - 12px);

                .MuiFab-root {
                    min-width: 24px;
                    width: 24px;
                    min-height: 24px;
                    height: 24px;
                    background-color: $grey-8;
                    box-shadow: 10.9714px 10.9714px 54.8571px rgba(0, 0, 0, 0.05);

                    &:hover {
                        background-color: $grey-6;
                    }
                }
            }

            .vdtValues {
                grid-area: values;

                &.fields {
                    align-self: flex-start;
                }

                .valuesList {
                    flex-grow: 1;
                    display: grid;
                    gap: 2px;
                    grid-template-columns: 1fr auto;
                    line-height: 1;
                    align-items: flex-end;

                    * {
                        width: auto;
                    }

                    .value {
                        text-align: right;
                        font-weight: bold;
                    }
                }

                .fromToValues {
                    display: grid;
                    grid-template-columns: 1fr auto 1fr;
                    grid-template-rows: 1fr 1fr;
                    grid-row-gap: 2px;
                    grid-column-gap: 4px;
                    font-weight: bold;
                    align-items: center;
                    margin-bottom: 8px;

                    .field {
                        display: flex;
                        flex-direction: column;

                        .name {
                            font-size: 0.7em;
                            color: $grey-5;
                            font-weight: normal;
                        }
                    }

                    .to-value {
                        text-align: right;
                    }

                    :last-child {
                        grid-area: 2 / 1 / 2 / 4;
                    }
                }
            }

            .vdtProgressBar {
                grid-area: progressbar;
                min-width: 0; // Allow progress bar to shrink if contents overflow the container...
            }

            .vdtChart {
                width: 800%;
                height: 800%;
                transform: scale(0.125, 0.125);
                transform-origin: 0 0;
                max-height: none;
            }
        }

        // TextField overrides for editable field values...
        .MuiOutlinedInput-root {
            border-radius: 2px;

            &.Mui-focused fieldset {
                border-width: 1px;
            }

            input {
                padding: 1px 0 0 3px;
                font-size: 0.5em;
            }
        }
    }

    &.simple {
        .vdtNodeHeaderSeparator, .vdtValues {
            display: none;
        }

        .vdtNodeHeader {
            // Make header take up no space in the layout, so that the progress bar can center itself vertically across the node body...
            height: 0px;
            overflow: visible;
        }

        .vdtNodeContainer .vdtNodeBody .vdtProgressBar {
            // Put the progress bar in the 1fr area in order to allow for proper centering...
            grid-area: values;

            .progressBarContainer > div {
                border: 1.47px solid white;
            }
        }
    }
}

.deemphasised, .deemphasized {
    &.to-value, &.delta-value {
        color: $grey-5;
    }

    &:not(.full) {
        .vdtNodeBackground {
            background-color: $grey-5;
        }
    }
}

.neutral {
    &.to-value, &.delta-value, &.value {
        color: $blue-2;
    }

    &.vdtLink {
        stroke: $blue-2;
    }

    &:not(.full) {
        .vdtNodeBackground {
            background-color: $blue-2;
        }

        &.high-opacity .vdtNodeHeader {
            color: white;
        }
    }
}

.positive {
    &.to-value, &.delta-value, &.value {
        color: $green-2;
    }

    &.vdtLink {
        stroke: $green-2;
    }

    &:not(.full) {
        .vdtNodeBackground {
            background-color: $green-2;
        }

        &.high-opacity .vdtNodeHeader {
            color: white;
        }
    }
}

.negative {
    &.to-value, &.delta-value, &.value {
        color: $red-2;
    }

    &.vdtLink {
        stroke: $red-2;
    }

    &:not(.full) {
        .vdtNodeBackground {
            background-color: $red-2;
        }

        &.high-opacity .vdtNodeHeader {
            color: white;
        }
    }
}

.vdtNodeAction {
    cursor: pointer;
}

.fieldLabel {
    &.to-value {
        color: $green-2;
    }
}

.fieldValue {
    &.negative {
        fill: $red;
    }

    &.positive {
        fill: $green;
    }
}

.vdtNodeHeaderContainer {
    fill: transparent;
    stroke: $grey-1;
    stroke-width: 0.5px;
}
