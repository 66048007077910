﻿@import "../../_variables.scss";

.BaseBadge-root {
    .MuiBadge-badge {
        color: white;
    }

    &.square, &.outlined {
        * {
            border-radius: 4px;
        }
    }

    &.outlined {
        &.red .MuiBadge-badge {
            color: $red-1;
            border: 1px solid $red-1;
            background-color: white;
        }

        &.orange .MuiBadge-badge {
            color: $orange-1;
            border: 1px solid $orange-1;
            background-color: white;
        }

        &.yellow .MuiBadge-badge {
            color: $yellow-1;
            border: 1px solid $yellow-1;
            background-color: white;
        }

        &.green .MuiBadge-badge {
            color: $green-1;
            border: 1px solid $green-1;
            background-color: white;
        }

        &.blue .MuiBadge-badge {
            color: $blue-1;
            border: 1px solid $blue-1;
            background-color: white;
        }

        &.darkblue .MuiBadge-badge {
            color: $dark-blue-1;
            border: 1px solid $dark-blue-1;
            background-color: white;
        }

        &.grey .MuiBadge-badge {
            color: $grey-6;
            border: 1px solid $grey-6;
            background-color: white;
        }
    }

    &.red .MuiBadge-badge {
        background-color: $red-1;
    }

    &.orange .MuiBadge-badge {
        background-color: $orange-1;
    }

    &.yellow .MuiBadge-badge {
        background-color: $yellow-1;
    }

    &.green .MuiBadge-badge {
        background-color: $green-1;
    }

    &.blue .MuiBadge-badge {
        background-color: $blue-1;
    }

    &.darkblue .MuiBadge-badge {
        background-color: $dark-blue-1;
    }

    &.grey .MuiBadge-badge {
        background-color: $grey-6;
    }
}
