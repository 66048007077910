﻿@import "../../_variables.scss";

.MuiCard-root {
    overflow-y: scroll !important;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
    }

    border-radius: 5px;
}

.MuiCardHeader-root {
    white-space: pre-wrap;
    padding: 12px 24px !important;
    background-color: $grey-7;
    color: black;
    min-height: 48px;
    box-sizing: border-box;

    > .MuiCardHeader-content {

        > .MuiCardHeader-title {
            font-size: 1rem;
            font-weight: bold;
        }
    }

    > .MuiCardHeader-action {
        margin: 0;
    }
}

.MuiCardContent-root {
    overflow-y: auto;
}

.MuiPaper-root.MuiCard-root {
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
}