@import "../../_variables.scss";

.canvas-with-filters {
    width: 100%;
    height: 100%;
    padding: 16px;

    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: 16px;
}

.canvas-filter-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;

    .canvas-filter {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span.filter-title {
            color: $grey-5;
            font-size: 12px;
        }

        .controls-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }

        &:not(:first-child) {
            border-left: 2px solid $grey-6;
            padding-left: 16px;
        }

        .MuiFormControl-root {
            border: none;
            background-color: $grey-7;
            border-radius: 4px;

            input, .MuiChip-label {
                font-size: 12px;
            }
        }

        button.MuiButton-root {
            font-size: 12px;
        }

        .MuiFormControl-root fieldset {
            border: none;
        }
    }
}