@import "../../_variables.scss";

.select-header {
    margin-bottom: 12px;
    margin-left: 6px;
    font-size: 1rem;
}

.multiselect-chip {
    margin-right: 8px;
}