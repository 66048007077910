@import "../../_variables.scss";

.canvas-debugger {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;

    .tabpanel {
        height: calc(100% - 56px);
        max-height: calc(100% - 56px);

        .action-panel {
            top: auto;
            bottom: 0;

            .MuiToolbar-root {
                justify-content: center;
            }
        }
    }

    .CodeMirror {
        height: 100%;
    }

    button.save-changes {
        margin-left: 36px;
        background-color: darkgreen;
    }

    .css-heading {
        background-color: $storychart-blue;
        color: white;
        text-align: center;
        font-weight: bold;
    }

    .table {
        width: 900px;
        border: 1px solid black;

        tr td:first-child {
            width: 150px;
        }

        .heading-cell {
            font-weight: bold;
            color: $storychart-dark-green;
            border-right: 1px dashed black;
            vertical-align: top;
        }
    }

    pre {
        max-width: 60vw;
        overflow: auto;
    }
}
