@import "../../../_variables.scss";

.min-max-indicator {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr 1fr;

    > * {
        overflow: hidden;
    }

    .left .progressBarContainer {
        border-radius: 9px 0 0 9px;
    }

    .right .progressBarContainer {
        border-radius: 0 9px 9px 0;
    }
}
