﻿@import "../../_variables.scss";

.MuiRadio-root {
    .radio-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border-width: 2px;
        border-style: solid;
        border-color: $grey-3;

        &[font-size=medium] {
            height: 16px;
            width: 16px;

            .radio-button-checked {
                height: 8px;
                width: 8px;
            }
        }

        &[font-size=small] {
            height: 12px;
            width: 12px;

            .radio-button-checked {
                height: 4px;
                width: 4px;
            }
        }

        .radio-button-checked {
            border-radius: 100%;
            border: 2px solid $grey-3;
            background-color: $grey-4;
        }
    }

    &.MuiRadio-colorPrimary {
        .radio-button-container {
            border-color: $primary-color;

            .radio-button-checked {
                border-color: $primary-color;
                background-color: $blue-3;
            }
        }
    }

    &.MuiRadio-colorSecondary {
        .radio-button-container {
            border-color: $secondary-color;

            .radio-button-checked {
                border-color: $secondary-color;
                background-color: $blue-4;
            }
        }
    }

    &.Mui-disabled {
        .radio-button-container {
            border-color: $grey-5;

            .radio-button-checked {
                border-color: $grey-5;
                background-color: $grey-6;
            }
        }
    }
}