@import "../../../_variables.scss";

.autocomplete {
    .MuiOutlinedInput-input {
        padding: 0;
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
        padding: 0;
    }

    .MuiInputBase-root, .MuiAutocomplete-popper {
        font-size: inherit !important;
    }

    .MuiAutocomplete-paper {
        font-size: 14px;
    }
}