﻿@import "../../_variables.scss";

span.MuiSwitch-root {
    margin: 6px 8px;
    width: 44px;
    height: 24px;
    padding: 0;
    overflow: visible;

    .MuiSwitch-switchBase {
        margin-top: -7px;
        margin-left: -7px;
    }

    &.MuiSwitch-sizeSmall {
        .MuiSwitch-switchBase {
            margin-top: -2px;
            margin-left: 0px;
        }
    }

    .MuiSwitch-track {
        box-sizing: border-box;
        background-color: $grey-5;
        border-radius: 12px;
        border: 0.7px solid $grey-4;
    }

    .MuiSwitch-thumb {
        box-sizing: border-box;
        border-radius: 100%;
        border-width: 1px;
        border-style: solid;
        border-color: $grey-4;
        background-color: white;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .switch-container-inner {
            box-sizing: border-box;
            height: 12px;
            width: 12px;
            border-radius: 100%;
            border: 0.7px solid $grey-4;
        }
    }

    span.Mui-checked + span.MuiSwitch-track {
        opacity: 1;
    }

    span.MuiSwitch-colorDefault.Mui-checked {
        + span.MuiSwitch-track {
            border-color: $grey-4;
            background-color: $grey-5;
        }

        .switch-container-inner {
            background-color: $grey-4;
        }
    }

    span.MuiSwitch-colorPrimary.Mui-checked {
        + span.MuiSwitch-track {
            border-color: $blue-2;
            background-color: $primary-color;
        }

        .switch-container-inner {
            background-color: $blue-3;
        }
    }

    span.MuiSwitch-colorSecondary.Mui-checked {
        + span.MuiSwitch-track {
            border-color: $blue-1;
            background-color: $secondary-color;
        }

        .switch-container-inner {
            background-color: $blue-4;
        }
    }

    span.Mui-disabled.Mui-checked {
        + span.MuiSwitch-track {
            border-color: $grey-7;
            background-color: #f5f5f5;
        }

        .switch-container-inner {
            background-color: white;
        }
    }
}
