@import "../../_variables.scss";

.jsx-parser {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 100%;
    overflow: hidden;
    background-color: $background-color;
}