.headless-vdt {
    background-color: white;
    
    // Since we're relying on the button sytling in the node header, but not actually using a Button element on this page, we have to manually import the required CSS...
    .MuiButtonBase-root {
        color: inherit;
        border: 0;
        cursor: pointer;
        margin: 0;
        display: inline-flex;
        outline: 0;
        padding: 0;
        position: relative;
        align-items: center;
        border-radius: 0;
        vertical-align: middle;
        -moz-appearance: none;
        justify-content: center;
        text-decoration: none;
        -moz-user-select: none;
        background-color: transparent;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }
}
