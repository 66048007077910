@import "../../_variables.scss";

.home-content {
    background-color: $grey-8;
    padding: 48px;
    text-align: center;
    width: auto;
    max-width: 800px;
    height: auto;
    margin: auto;
    display: block;
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    pre {
        white-space: normal;
    }
}

#home-logo {
    width: 400px;
}

#home-header-1 {
    color: $storychart-blue;
}

#home-header-2 {
    color: $storychart-red;
}