@import "../../_variables.scss";

.navbar {
    z-index: 0;
}

.navbar > .MuiToolbar-root {
    z-index: 0;
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-template-rows: auto 1fr;
    grid-template-areas: "breadcrumb custom-content actions" "title custom-content _";
    align-items: center;
    padding: 4px 8px 0 24px;
    height: 64px;

    .breadcrumb {
        grid-area: breadcrumb;
        display: flex;
        align-items: baseline;
        list-style: none;
        padding-inline-start: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        color: $grey-4;
        font-size: 14px;

        li:not(:first-child) {
            &::before {
                content: "/";
                padding: 0 16px;
            }
        }

        div.MuiInputBase-root {
            font-size: inherit;

            .MuiSelect-select {
                padding: 0 36px 0 0;
            }

            .MuiSelect-icon {
                vertical-align: middle;
                height: 14px;
                top: calc(50% - 7px);
                margin-top: -2px;

                &.MuiSelect-iconOpen {
                    margin-top: 2px;
                }
            }

            fieldset {
                border-width: 0;
            }

            a {
                text-decoration: none;
                color: $grey-4;
            }
        }
    }

    .actions {
        grid-area: actions;
        height: 24px;

        button {
            height: 24px;
            width: 24px;
            padding: 0;
        }
    }

    .page-title {
        grid-area: title;
        font-size: 18px;
        color: $grey-3;
        font-weight: bold;
    }

    .custom-content {
        grid-area: custom-content;
        align-items: center;
    }
}

.hide-titlebar .navbar {
    display: none;
}

.breadcrumb-dropdown {
    li {
        padding: 0;
    }

    a {
        padding: 6px 12px;
        text-decoration: none;
        color: $grey-4;
        font-size: 14px;
        width: 100%;
    }
}