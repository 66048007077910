@import "../../_variables.scss";

.storyline {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
    align-self: stretch;
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.main-canvas {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 100%;
    overflow: hidden;
    background-color: $background-color;
}