﻿div.MuiTextField-root {
    border-radius: 4px;

    .MuiInputBase-root.Mui-focused {
        box-sizing: border-box;
        overflow: visible;

        &:not(.Mui-error) {
            box-shadow: 0 0 7px 7px rgba(137, 186, 237, 0.42);
        }

        &.Mui-error {
            box-shadow: 0 0 7px 7px rgba(237, 137, 186, 0.42);
        }
    }

    .Mui-error .MuiIconButton-root {
        color: $grey-4;
        padding: 8px;

        svg > path {
            fill: $grey-4;
        }
    }
}

.MuiFormHelperText-root.Mui-error {
    margin-left: 0;
}