.focus-container {
    position: relative;

    .buttons {
        display: flex;
        position: absolute;
        visibility: hidden;
        top: 2px;
        right: 2px;
        flex-direction: column;
        z-index: 100;
    }

    &:hover {
        .buttons {
            visibility: visible;
        }
    }

    &.restored {
        > * {
            opacity: 0;
            animation: focus-container-restored 0.5s 0.3s ease-in forwards;
        }

        @keyframes focus-container-restored {
            to {
                opacity: 1;
            }
        }
    }

    &.focused {
        position: absolute;
        top: 8px;
        bottom: 8px;
        left: 8px;
        right: 8px;
        background-color: white;
        z-index: 99;

        > * {
            opacity: 0;
            animation: focus-container-focused 0.5s 0.3s ease-in forwards;
        }

        @keyframes focus-container-focused {
            to {
                opacity: 1;
            }
        }
    }
}
