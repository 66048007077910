@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slide-in {
    from {
        margin-left: -300px;
        margin-right: 300px;
    }

    to {
        margin-left: 0;
        margin-right: 0;
    }
}

.annotations {
    width: 300px;
    margin-right: 20px;

    .item {
        font-size: 0.875rem;
        text-align: left;
        margin-bottom: 1.5rem;
        //animation: 1s fade-in;

        b {
            color: crimson;
        }
    }
}

.hide-commentary {
    .commentary, .annotations {
        display: none;
    }
}
