@import "../../../_variables.scss";

div.timer {
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
    display: grid;
    grid-template-rows: 1fr auto;

    .stopwatch-icon {
        width: 100%;
        height: 100%;
    }

    .remaining-time-text {
        text-align: center;
        font-size: 2em;
        margin-bottom: 8px;
        font-weight: bold;

        &.started {
            color: $green-2;
        }

        &.warning {
            color: $orange-2;
        }

        &.critical {
            color: $red-2;
        }
    }
}
