@import "../../../_variables.scss";

.mosaic-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.mosaic {
    display: grid;
    grid-auto-rows: 1fr;
    grid-auto-columns: 1fr;
    align-items: stretch;
    position: absolute;
    min-width: 100%;
    min-height: 100%;

    .col-fill {
        flex-basis: 0;
    }

    .heading {
        display: flex;
        flex-grow: 1;
        background: $grey-7;
        color: $grey-5;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-basis: 0;
        padding: 1rem;
        word-wrap: normal;
        position: sticky;
        z-index: 5;

        &.top-row {
            top: 0;
        }

        &.left-row {
            left: 0;
        }
        // Top-left cell...
        &:nth-child(1) {
            z-index: 6;
            top: 0;
            left: 0;
        }
    }

    &.small {
        .cell {
            font-size: 0.625rem;
        }
    }

    &.medium {
        .cell {
            font-size: 0.75rem;
        }
    }

    &.large {
        .cell {
            font-size: 0.875rem;
        }
    }

    &.x-large {
        .cell {
            font-size: 1rem;
        }
    }

    &.xx-large {
        .cell {
            font-size: 1.25rem;
        }
    }

    &.xxx-large {
        .cell {
            font-size: 1.5rem;
        }
    }

    &.huge {
        .cell {
            font-size: 2rem;
        }
    }

    .cell {
        display: flex;
        flex-flow: column nowrap;
        flex-basis: 0;
        align-items: stretch;
        font-size: 0.75rem;
        overflow: hidden;
        min-width: 150px;
        min-height: 60px;
        height: 100%;
        box-sizing: border-box;
        background-color: white;

        &.bordered-left {
            border-left: 1px solid $grey-7;
        }

        &.bordered-top {
            border-top: 1px solid $grey-7;
        }

        .fields {
            flex-grow: 1;
            flex-shrink: 1;
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            justify-content: center;
            padding: 0 2px;
            overflow: hidden;
            text-align: center;
        }

        .progressBarContainer {
            margin: 0 8px 4px 8px;
        }
    }
}

.mosaic-tooltip {
    font-size: 0.875rem;
}