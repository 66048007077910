﻿@import "../../_variables.scss";

div.MuiTabs-root {
    background-color: $dark-blue-4;
    overflow: unset;
    flex-shrink: 0;

    .MuiTab-root {
        color: $grey-5;
        margin: 8px;
        border-radius: 4px;
        min-height: 40px;

        &.MuiButtonBase-root {
            padding: 8px 16px;
            line-height: 1;
        }

        &.Mui-selected {
            color: $blue-1;
            background-color: $grey-8;
            box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
        }

        .MuiTouchRipple-root {
            .MuiTouchRipple-child {
                background-color: $blue-3;
            }
        }
    }

    .MuiTabs-indicator {
        display: none;
    }
}