@import "./_variables.scss";
@import "./theming/material-ui-overrides/index.scss";

@each $value in $displays {
    .d-#{$value} {
        display: $value !important;
    }
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

@for $i from 1 through 10 {
    @for $j from 1 through 10 {
        .grid-#{$i}-#{$j} {
            display: grid;
            box-sizing: border-box;
            grid-template-columns: repeat($i, 1fr);
            grid-template-rows: repeat($j, 1fr);
            grid-gap: 1rem;
            width: 100%;
            height: 100%;
        }
    }
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

.plush {
    padding: 16px !important;
}

.flush {
    padding: 0 !important;
}

.spaced-right {
    margin-right: 16px !important;
}

.spaced-left {
    margin-left: 16px !important;
}

.spaced-top {
    margin-top: 16px !important;
}

.spaced-bottom {
    margin-bottom: 16px !important;
}

@each $side in $sides {
    @for $i from 1 through 5 {
        .margin-#{$side}-#{$i} {
            margin-#{$side}: #{$i * 8}px !important;
        }
    }
}

@for $i from 1 through 5 {
    .gap-#{$i} {
        gap: #{$i * 8}px !important;
    }
}

.flex-row.space-between {
    > *:not(:last-child) {
        margin-right: 16px;
    }
}

.flex-column.space-between {
    > *:not(:last-child) {
        margin-bottom: 16px;
    }
}

.text-smallest {
    font-size: 0.75rem;
}

.text-smaller {
    font-size: 0.875rem;
}

.text-normal {
    font-size: 1rem;
}

.text-larger {
    font-size: 1.125rem;
}

.text-largest {
    font-size: 1.25rem;
}

.text-align-center {
    text-align: center;
}

.font-weight-bold {
    font-weight: bold;
}

.font-weight-normal {
    font-weight: normal;
}

.bg-primary {
    background-color: $primary-color !important;
}

.bg-secondary {
    background-color: $secondary-color !important;
}

.bg-light-blue {
    background-color: #5ca9dd !important;
}

.bg-storychart-color-1 {
    background-color: $storychart-color-1 !important;
}

.bg-storychart-color-2 {
    background-color: $storychart-color-2 !important;
}

.bg-storychart-color-3 {
    background-color: $storychart-color-3 !important;
}

.bg-storychart-color-4 {
    background-color: $storychart-color-4 !important;
}

.bg-storychart-color-5 {
    background-color: $storychart-color-5 !important;
}

.bg-storychart-color-6 {
    background-color: $storychart-color-6 !important;
}

.bg-storychart-color-7 {
    background-color: $storychart-color-7 !important;
}

.bg-storychart-color-8 {
    background-color: $storychart-color-8 !important;
}

.bg-storychart-color-9 {
    background-color: $storychart-color-9 !important;
}

.fg-primary {
    color: $primary-color !important;
}

.fg-secondary {
    color: $secondary-color !important;
}

.fg-storychart-color-1 {
    color: $storychart-color-1 !important;
}

.fg-storychart-color-2 {
    color: $storychart-color-2 !important;
}

.fg-storychart-color-3 {
    color: $storychart-color-3 !important;
}

.fg-storychart-color-4 {
    color: $storychart-color-4 !important;
}

.fg-storychart-color-5 {
    color: $storychart-color-5 !important;
}

.fg-storychart-color-6 {
    color: $storychart-color-6 !important;
}

.fg-storychart-color-7 {
    color: $storychart-color-7 !important;
}

.fg-storychart-color-8 {
    color: $storychart-color-8 !important;
}

.fg-storychart-color-9 {
    color: $storychart-color-9 !important;
}

@each $color in map-keys($colors) {
    .icon-#{$color} path, .icon.#{$color} path {
        fill: map-get($colors, $color) !important;
    }

    .bg-#{$color} {
        background-color: map-get($colors, $color) !important;
    }

    .fg-#{$color} {
        color: map-get($colors, $color) !important;
    }
}

.button-delete {
    background-color: $storychart-red !important;
    color: white !important;

    &:hover {
        background-color: darken($storychart-red, 10%) !important;
    }
}

body {
    background-color: $background-color;
    padding: 0;
    margin: 0;
    font-family: $primary-font;

    * {
        box-sizing: border-box;
    }
}

#root {
    margin: 0;
    padding: 0;
    height: 100vh;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: "sidenav content";

    @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        height: -webkit-fill-available;
    }
}

.content {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    height: 100vh;
    grid-area: content;

    @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        height: -webkit-fill-available;
    }
}

.hamburger-icon {
    display: inline !important;
    position: fixed !important;
    left: 10px;
    top: 10px;
    color: $primary-color;
}

h1, h2, h3, h4, h5, h6 {
    margin-block-start: 0.3em;
    margin-block-end: 0.1em;
    text-align: center;
}

h3 {
    font-size: 1.25rem;
}

.row {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    align-items: stretch;
    align-content: stretch;
    max-height: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.col-fixed {
    min-width: 0;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
}

.col-fill {
    min-width: 0;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
}

.fill {
    width: 100%;
    height: 100%;
}

.loading-spinner {
    width: 64px;
    height: 64px;
    margin: auto;
    display: block;
    margin: 0;
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
}

.loading-spinner-inline {
    margin-left: calc(50% - 32px);
}

table.table {
    border-spacing: 0;

    &.bordered {
        border-radius: 8px;

        tr {
            td:first-child {
                border-left: 1px solid $grey-6;
            }

            td:last-child {
                border-right: 1px solid $grey-6;
            }

            &:last-child td {
                border-bottom: 1px solid $grey-6;
            }
        }
    }

    td, th {
        padding: 0.875rem 1rem;
    }

    thead {
        tr {
            margin: -1px;
        }
    }

    td:not(:last-child) {
        border-right: 1px solid $grey-6;
    }

    tr:not(:last-child) td {
        border-bottom: 1px solid $grey-6;
    }

    th {
        padding: 1em;
        background-color: $grey-8;
        border: 1px solid $grey-6;
        color: $blue-2;
    }

    &.striped tbody {
        tr:nth-child(odd) {
            background: white;
        }

        tr:nth-child(even) {
            background: $grey-8;
        }
    }

    &.compact {
        td, th {
            padding: 0;
        }

        thead {
            th {
                padding: 0.5em;
            }
        }
    }
}

.card {
    background-color: white;
}

.js-plotly-plot * {
    .gtitle {
        font-weight: bold !important;
    }
}

.screen-centered {
    width: auto;
    height: auto;
    max-height: calc(100% - 200px);
    margin: auto;
    display: block;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

form {
    .MuiFormControl-fullWidth {
        padding-bottom: 16px;
    }
}

.MuiMenu-list > a {
    text-decoration: none;
    color: inherit;
}

.MuiTable-root {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.MuiTable-stickyHeader {
    border-collapse: separate;
}

.MuiTableHead-root .MuiTableCell-head {
    background-color: $primary-color;
    color: white;

    .MuiTableSortLabel-root:hover {
        color: $grey-7;
    }

    .MuiTableSortLabel-root.MuiTableSortLabel-active {
        color: $grey-5;
    }

    .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
        color: $grey-5;
    }
}

.MuiTableCell-root {
    display: table-cell;
    padding: 16px;
    font-size: 0.875rem;
    text-align: left;
    font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
}

.MuiTableCell-head {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    line-height: 1.5rem;
}

.MuiTableCell-body {
    color: rgba(0, 0, 0, 0.87);
}

.MuiTableCell-footer {
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    line-height: 1.3125rem;
}

.MuiTableCell-sizeSmall {
    padding: 6px 24px 6px 16px;
}

.MuiTableCell-sizeSmall:last-child {
    padding-right: 16px;
}

.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox {
    width: 24px;
    padding: 0px 12px 0 16px;
}

.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox:last-child {
    padding-left: 12px;
    padding-right: 16px;
}

.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox > * {
    padding: 0;
}

.MuiTableCell-paddingCheckbox {
    width: 48px;
    padding: 0 0 0 4px;
}

.MuiTableCell-paddingCheckbox:last-child {
    padding-left: 0;
    padding-right: 4px;
}

.MuiTableCell-paddingNone {
    padding: 0;
}

.MuiTableCell-paddingNone:last-child {
    padding: 0;
}

.MuiTableCell-alignLeft {
    text-align: left;
}

.MuiTableCell-alignCenter {
    text-align: center;
}

.MuiTableCell-alignRight {
    text-align: right;
    flex-direction: row-reverse;
}

.MuiTableCell-alignJustify {
    text-align: justify;
}

.MuiTableCell-stickyHeader {
    top: 0;
    left: 0;
    z-index: 2;
    position: sticky;
    background-color: #fafafa;
}

.MuiTableBody-root {
    tr:nth-child(even) {
        td {
            background-color: #f0f0f0;
        }
    }
}

.MuiToolbar-root {
    .MuiIconButton-root {
        .icon-add {
            font-size: 32px !important;
        }
    }
}

.icon-add {
    color: $green;
}

.icon-edit {
    color: $primary-color;
}

.icon-delete {
    color: $red;
}

.MuiTooltip-tooltip {
    font-size: 0.75rem !important;
}

.MuiIconButton-root.feedback-button {
    margin-left: -10px;
}

div.tooltip-primary {
    opacity: 1 !important;
    padding: 8px !important;
    border-radius: 7px !important;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
}

.tooltip-primary-arrow {
    color: darken($primary-color, 5%) !important;
}
// Styles applied to the split panel's drag area...
.gutter {
    &:hover {
        background-color: rgba($primary-color, 0.2);
    }

    &.gutter-horizontal {
        &:hover {
            cursor: col-resize;
        }
    }

    &.gutter-vertical {
        &:hover {
            cursor: row-resize;
        }
    }
}

.MuiAutocomplete-tag {
    margin-right: 8px;
}

.mini-scroll-bar {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $grey-4 $grey-6; /* scroll thumb & track */
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: $grey-6;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $grey-4;
        border: 1px solid $grey-6;
    }
}

.form-errors {
    margin-top: 16px;
    border-radius: 5px;
    padding: 15px;
    background: $storychart-red;
    color: white;
}

.MuiList-root {
    .MuiListItemIcon-root {
        min-width: 3rem;
        color: $storychart-blue;

        svg, path {
            fill: $storychart-blue;
        }
    }
}

.centered {
    align-items: center;
    justify-content: center;
}

button {
    font-family: $primary-font;
}

.vertical-form-field {
    color: $grey-4;
    margin-bottom: 1em;
}

.data-placeholders {
    height: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 2rem;

    img {
        box-sizing: border-box;
        height: calc(100% - 70px);
        max-height: 300px;
        max-width: 100%;
        object-fit: scale-down;
        padding-bottom: 1rem;
    }
}

.container-loader {
    padding: 2rem;
}
// Most canvases use the AppBar to host the Tabs component - remove the drop shadow from that container...
.main-canvas .MuiAppBar-root {
    box-shadow: none;
}

hr {
    border-style: none;
    border-bottom: none;
    border-top: 0.5px solid $grey-6;
    width: auto;
}
// Hide the up/down arrows for all numeric input fields...
input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}
// React joyride overlay does not handle window resize events, so force the height here...
.react-joyride__overlay {
    height: 100vh !important;

    @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        height: -webkit-fill-available !important;
    }
}
// Remove the joyride beacon from the layout when disabled/hidden...
span[x-placement=center] button.react-joyride__beacon {
    display: none !important;
}
