@import "../../_variables.scss";

div.MuiAccordion-root {
    border: none;
    border-radius: 4px;
    outline: none;
    box-shadow: none;

    &.MuiAccordion-gutters:before {
        background-color: transparent;
    }

    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        transform: none;
    }

    .MuiAccordionSummary-root {
        flex-direction: row-reverse;
        color: $grey-1;

        .MuiAccordionSummary-expandIconWrapper {
            padding: 2px;
            margin-right: 1rem;
            background-color: white;
            border-radius: 100%;

            svg > path {
                fill: $blue-1;
            }
        }
    }

    .MuiAccordionDetails-root {
        color: $grey-4;
        padding: 0 1rem 1rem 1rem;
        margin-left: 3rem;
    }

    &.Mui-expanded {
        background-color: $dark-blue-4;

        .MuiAccordionSummary-expandIconWrapper {
            box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
        }
    }
}
