﻿@import "../../_variables.scss";

button.MuiButton-root {
    text-transform: none !important;
    font-size: 1em;
    font-weight: bold;
    min-width: unset;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);

    &.MuiButton-sizeSmall {
        font-size: 0.75em;
        padding: 4px 12px;
    }

    &.MuiButton-sizeMedium {
        font-size: 1em;
        padding: 6px 16px;
    }

    &.MuiButton-sizeLarge {
        font-size: 1.25em;
        padding: 6px 16px;
    }

    &.MuiButton-text {
        box-shadow: none;
        text-decoration-line: underline;
    }
}

button.MuiButton-outlined {
    background-color: $button-outline-background;
    border-radius: 4px;
}

button.MuiButtonBase-root {
    &.MuiFab-root {
        box-shadow: none;
    }
}