.container-loader {
    .container-images {
        display: grid;
        // Move images to the top-right corner and use margins to align the spinner to the backdrop...
        place-items: flex-end;
        align-items: flex-start;

        > * {
            grid-area: 1 / 1;
        }

        #loading-spinner {
            margin-right: 39px;
            margin-top: 30px;
            animation-name: spin;
            animation-duration: 5000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;

            @keyframes spin {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(360deg);
                }
            }
        }
    }
}
