﻿@import "../../_variables.scss";

span.MuiSlider-root {
    box-sizing: border-box;
    // Provide space for the always-visible value label and the thumb halo...
    margin: 24px 16px 16px 16px;
    width: calc(100% - 32px);

    &.MuiSlider-vertical {
        width: 4px;
        height: 100%;
    }

    .MuiSlider-rail {
        background-color: $grey-7;
        border: 0.7px solid $grey-5;
    }

    .MuiSlider-track {
        height: 4px;
    }

    .MuiSlider-thumb {
        border: 1px solid $grey-3;
        background-color: white;

        .MuiSlider-thumb-middle {
            height: 10px;
            width: 10px;
            border-radius: 100%;
            background-color: $blue-3;
            border: 1px solid $grey-3;
        }
    }

    .MuiSlider-valueLabel {
        border-radius: 2px;
        background-color: $blue-1;
        color: white;
        top: -5px;
        padding: 0 4px;

        &:before {
            display: none;
        }
    }

    &.Mui-disabled {
        .MuiSlider-valueLabel {
            background-color: $grey-6;
            color: $grey-5;
        }

        .MuiSlider-thumb {
            background-color: $grey-7;

            .MuiSlider-thumb-middle {
                background-color: $grey-7
            }
        }
    }
}