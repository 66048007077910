@import "../../_variables.scss";

.icon {
    padding: 2px;
    box-sizing: content-box;

    &.white {
        fill: white;
        stroke: white;

        path {
            fill: white;
            stroke: white;
        }
    }

    &.black {
        fill: black;
        stroke: black;

        path {
            fill: black;
            stroke: black;
        }
    }

    &.primary {
        fill: $primary-color;
        stroke: $primary-color;

        path {
            fill: $primary-color;
            stroke: $primary-color;
        }
    }

    &.small {
        width: 14px;
        height: 14px;
    }

    &.medium {
        width: 24px;
        height: 24px;
    }

    &.large {
        width: 36px;
        height: 36px;
    }
}
