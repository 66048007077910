@import "../../_variables.scss";

.minimap-hover-tooltip {
    padding: 4px;

    .hover-tooltip-slide-title {
        font-size: 12px;
        text-align: center;
        max-width: 15vw;
    }
}

.minimap-hover-tooltip-content {
    .slide {
        margin: 8px 4px 4px 4px;
        width: 15vw;
        max-width: 15vw;
        height: 15vh;
        max-height: 15vh;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;

        .jsx-parser {
            width: 100vw;
            height: 100vh;
            transform: scale(0.15, 0.15);
            transform-origin: 0 0;
            max-height: none;
        }
    }
}

.minimap-loading {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.minimap {
    // Remove tooltip from DOM when not being shown.  This prevents interactive elements from stealing mouse focus when the tooltip is not shown...
    .__react_component_tooltip {
        display: none;

        &.show {
            display: inline-block;
        }
    }
}
