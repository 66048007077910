@import "../../../_variables.scss";

.datagrid-container {
    height: 100%;
    overflow: hidden;

    .react-grid-Grid {
        box-sizing: border-box;
        min-height: 100% !important;

        * {
            box-sizing: border-box;
        }

        .react-grid-HeaderRow {
            .react-grid-HeaderCell {
                background: $storychart-blue;
                color: white;
                display: flex !important;
                align-items: center;
            }
        }

        .react-grid-Row {
            &:hover {
                .react-grid-Cell {
                    background-color: #f0f0f0 !important;
                }
            }

            &.react-grid-Row--odd {
                .react-grid-Cell {
                    background-color: #f9f9f9;
                }
            }
        }
    }

    .react-grid-Container {
        height: 100%;
    }

    .react-grid-Main {
        height: 100%;
    }

    .react-grid-Canvas {
        height: 100% !important;
    }
    // Hide the single-cell copy indicator, since we're allowing for ranged cell copies...
    .react-grid-cell-copied {
        display: none;
    }

    .rdg-selected-range {
        border: 2px solid #66afe9;
    }
    // Hide the single-cell selection block if there is a ranged selection...
    .rdg-selected-range ~ .rdg-selected {
        border: none;
    }
}

.rdg-editor-container {
    box-sizing: border-box;
    border: 2px solid #66afe9;
    border-radius: 0;

    input.editor-main, input.editor-main:focus, select.editor-main, select.editor-main:focus {
        background: white;
        border: none;
        border-radius: 0 !important;
        height: 100% !important;
    }

    > * {
        outline: none;
        border-radius: 0 !important;
        width: 100%;
        height: 100% !important;
    }

    * {
        box-sizing: border-box !important;

        input.editor-main, input.editor-main:focus, select.editor-main:focus {
            background: white;
            border: none;
            border-radius: 0;
            height: 100% !important;
            padding: 0 4px;
        }

        &.MuiInput-underline:after, &.MuiInput-underline:before, &.MuiInput-underline:hover:not(.Mui-disabled):before {
            border-radius: 0;
            border-bottom: none;
        }
    }

    .MuiFormHelperText-root {
        margin-top: 5px;
        background: white;
    }

    .MuiInputBase-root {
        height: 100%;
    }
}
